<script lang="ts">
    import MyMarket from './MyMarket.svelte';
    import { inertia } from '@inertiajs/svelte';

    interface User {
        username: string;
        name: string;
    }

    interface Listing {
        expiry_time?: string;
    }

    interface Offer {
        user: User;
        listing: Listing;
    }

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    };

    export let offers: Offer[];

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
</script>

<svelte:component this="{MyMarket}" />

<div class="flex h-screen flex-col bg-gray-100">
    <div class="flex flex-1 items-start justify-center p-4">
        <div class="w-full max-w-4xl rounded-xl border border-gray-200 bg-white shadow-2xl">
            <div class="table-wrapper">
                {#if offers.length > 0}
                    <div class="p-4">
                        <div class="overflow-x-auto">
                            <table class="w-full table-auto border-collapse">
                                <thead>
                                    <tr class="border-b border-gray-200">
                                        <th class="px-4 py-3 text-left text-sm font-semibold text-gray-700">User</th>
                                        <th class="px-4 py-3 text-left text-sm font-semibold text-gray-700">Expiry</th>
                                        <th class="px-4 py-3 text-left text-sm font-semibold text-gray-700">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each offers as offer}
                                        <tr class="transition-colors duration-200 hover:bg-gray-50">
                                            <td class="px-4 py-3 text-gray-800">
                                                <a
                                                    href="/{offer.user.username}"
                                                    class="font-medium text-indigo-600 transition-colors hover:text-indigo-800"
                                                    use:inertia>{offer.user.name}</a
                                                >
                                            </td>
                                            <td class="px-4 py-3 text-gray-600">
                                                {offer.listing.expiry_time
                                                    ? dateFormatter.format(new Date(offer.listing.expiry_time))
                                                    : '-'}
                                            </td>
                                            <td class="px-4 py-3">
                                                <a
                                                    href="/{offer.user.username}"
                                                    class="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-white transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    use:inertia>Visit</a
                                                >
                                            </td>
                                        </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                    </div>
                {:else}
                    <p class="p-4 text-center text-gray-500">No Record Found</p>
                {/if}
            </div>
        </div>
    </div>
</div>
